<template>
  <div class="download">
    <!-- <div class="download-banner">
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/vrbuild.1bf39d0b.png" v-if="!$isMobile()">
      <img src="http://0vr1.com/video/2021-06-21/2021/FrontPic/img/vrbuild.f3fc4e45.png" v-else />
      <div class="download-banner-title">
        <h3>下载中心</h3>
        <span> DOWNLOAD CENTER</span>
      </div>
    </div> -->
    <div class="download-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> -> <span
        @click="$router.push({ path: '/download' })">下载中心</span>
    </div>
    <div class="download-content">
      <download-content :isPage="isPage" :isPageSize="4"></download-content>
    </div>
  </div>
</template>
<script>
import downloadContent from './download-content'
export default {
  name: 'Download',
  props: {
    isPage: {
      type: Boolean,
      default: true
    }
  },
  components: {
    downloadContent,
  },
}
</script>
<style scoped lang="scss">
.download {
  position: relative;

  &-banner {
    width: 100%;
    position: relative;
    width: 100%;

    img {
      width: 100%;
    }

    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;

      h3 {
        font-size: 22px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
        font-family: Microsoft YaHei;
      }

      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        font-family: Microsoft YaHei;
      }
    }
  }

  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 30px 30px 30px;
    background: #fff;
    margin-bottom: 40px;
  }

  &-header {
    margin-top: 60px;
    align-items: center;
  }

  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 10px;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }
}


::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>