<template>
    <div class="downloadToast">
        <p class="downloadToast-title">提示</p>
        <p class="downloadToast-info">需要登陆后才能下载文件</p>
        <div class="downloadToast-btnBox">
            <div @click="showDownloadModal">去登陆</div>
            <div @click="showRegisterModal">去注册</div>
        </div>
    </div>
</template>
<script>
import Bus from '@/components/bus.js'
export default {
    methods: {
        showRegisterModal() {
            Bus.$emit('showRegisterModal');
            this.$toast.dismiss("download");
        },
        showDownloadModal() {
            Bus.$emit('showDownloadModal');
            this.$toast.dismiss("download");
        }
    }
}
</script>
<style scoped lang="scss">
.downloadToast {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
        width: 100%;
        font-size: 16px;
        margin: 0;
        padding: 0;
        line-height: normal;
    }

    &-info {
        width: 100%;
        font-size: 14px;
        margin: 8px 0 0 0;
        padding: 0;
        line-height: normal;
    }

    &-btnBox {
        display: flex;
        width: 80%;
        margin: 20px 0 0 0;
        gap: 8px;

        div {
            flex: 1;
            padding: 5px 0;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;

            &:hover {
                background: #0c6bba;
            }
        }
    }
}
</style>