<template>
  <div class="download">
    <div style="height: 20px"></div>
    <div class="download-link" v-for="(item, index) in links" :key="index" @click="downLoad(item)">
      <div class="download-link-icon">
        <img :src="require('@imgs/pdf@3x.png')" />
      </div>
      <div class="download-link-title l-1-overflow">{{ item.title }}</div>
      <div class="download-link-icon">
        <img style="width: 18px" :src="require('@imgs/downLoad.png')" />
      </div>
    </div>
    <page v-if="isPage" :total="total" @pageChange="getList"></page>
    <login-modal ref="loginModal"></login-modal>
  </div>
</template>
<script>
import page from "@components/page/page.vue";
import Component from "./Component.vue";
export default {
  name: "downloadContent",
  props: {
    isPage: {
      type: Boolean,
      default: true,
    },
    pageSize: {
      type: Number,
      default: 8,
    },
  },
  components: {
    page,
    Component
  },
  created() {
    this.getList(1);
    const userInfo = localStorage.getItem('authToken');
    if (!userInfo) {
      this.$toast.info({
        component: Component,
      }, {
        id: "download",
        position: "top-center",
        timeout: 3000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      return
    }
  },
  data() {
    return {
      links: [],
      total: 0,
    };
  },
  methods: {
    getList(pageNum) {
      let url = "/downloadcenter/list";

      if (pageNum === 1) {
        this.links = [];
        this.vrItems = [];
      }
      this.$http({
        url,
        params: {
          pageNum,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        this.links = res.rows || [];
        this.total = res.total;
      });
    },
    downloadFile(data, fileName) {
      // new Blob 实例化文件流
      const url = window.URL.createObjectURL(new Blob(data))
      const link = document.createElement('a')
      link.style.display = "none"
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      //下载完成移除元素
      document.body.removeChild(link)
      //释放掉blob对象
      window.URL.revokeObjectURL(url)
    },
    downLoad(row) {
      const userInfo = localStorage.getItem('authToken');
      if (!userInfo) {
        this.$toast.info({
          component: Component,
        }, {
          id: "download",
          position: "top-center",
          timeout: 3000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        return
      }
      const fileTitle = row.title;
      const fileUrl = row.fileUrl;
      this.$http({
        url: "/downloadFile",
        params: {
          fileTitle,
          fileUrl,
        },
        responseType: 'blob',
      }).then(res => {
        let blob = res
        let content = [];
        //let fileName = fileUrl.substring(fileUrl.lastIndexOf('/')+1);
        let fileName = row.title + fileUrl.substring(fileUrl.lastIndexOf('.'));
        //  必须把blob内容放到一个数组里
        content.push(blob);
        this.downloadFile(content, fileName);
      }).catch((err) => {
        this.$toast.error(err.toString(), {
          timeout: 1500
        });
      });
    }
  },
};
</script>
<style scoped lang="scss">
.download {

  &-link {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 62px;
    border-bottom: 1px solid #eeeeee;

    &-icon {
      img {
        width: 32px;
      }
    }

    &-title {
      flex: 1;
      padding-left: 15px;
      font-size: 16px;
      font-weight: 400;
      color: #666666;
      font-family: Microsoft YaHei;
    }

    &-title:active,
    &-title:hover {
      color: #2870b6;
    }
  }
}
</style>
